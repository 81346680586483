@import '~styles/index.scss';

.addFallbackOpCode {
  &Select {
    &__control {
      height: 48px;
      margin-bottom: 20px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);

      &--is-disabled {
        background-color: #dedee0 !important;
      }
    }

    &__placeholder {
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
    }

    &__menu {
      overflow: hidden;
      max-height: 150px;
      font-family: $ff-lato;
      font-size: map-get($sizes, regular);
    }

    &__menu-list {
      height: 150px;
    }
  }
}
