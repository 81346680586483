@import '~styles/index.scss';

.chooseRemoteService {
  .buttonsSection {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: rgba(249, 250, 252, 0.6);
    align-items: center;
    justify-content: center;
  }

  .submit {
    display: flex;
    margin: 20px 0 0;
    justify-content: flex-end;
  }

  :global {
    .conciergeAlert,
    .additionalInfoItemColumn {
      margin-top: 16px;
    }
  }
}

.sameDropOffAddress {
  margin-top: 15px;
}
