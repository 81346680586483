@import '~styles/index.scss';

:global {
  .conciergeApp {
    height: 100%;
    min-height: 100vh;
  }
}

.menuTemplatesPage {
  .main {
    @include horizontally-centered;

    padding: 16px 0;
  }

  .loadingPanel {
    @include main-font(map-get($sizes, bigger));

    width: 100%;
    text-align: center;
    color: map-get($colors, pink);
  }
}

.search {
  position: relative;
  display: flex;
  width: 300px;
  height: fit-content;
  padding: 10px;
  border: $default-border;
  border-radius: $small-border-radius;
  background-color: map-get($colors, white);
  justify-content: space-between;

  &Input {
    @include main-font;

    width: 100%;
    outline: none;
    border: none;

    &::placeholder {
      color: map-get($colors, font-gray);
    }
  }

  &Icon {
    cursor: pointer;
  }
}

.navbar {
  display: flex;
  height: 48px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
}

.table {
  overflow-x: auto;

  :global {
    .rt-th {
      display: flex;
      min-height: 60px;
      white-space: unset;
      align-items: center;
    }

    .rt-noData {
      padding-top: 50px;
    }

    .rt-table {
      overflow: visible;
    }

    .rt-tbody {
      overflow: visible;
    }
  }

  .ellipsis {
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .alignment {
    white-space: normal;
  }

  .withDescription {
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: center;
  }
}

.editRecallModal {
  :global {
    .conciergeModalBody {
      .fieldBlock {
        @include main-font;

        .conciergeInputWrapper {
          margin: 0;
        }

        .conciergeInputLabel {
          font-size: map-get($sizes, regular);
          line-height: 18px;
        }

        display: block;
        margin: 16px 0;
        font-size: map-get($sizes, regular);
        line-height: 18px;
        color: map-get($colors, main-black);
      }

      .conciergeModaInputCheckbox {
        width: 16px;
        height: 16px;
      }

      p {
        font-family: $ff-lato;
        font-size: $fs-smaller;
        line-height: 16px;
      }

      .recallLabel {
        padding-bottom: 6px;
        font-weight: $fw-normal;
      }

      .availability {
        margin-bottom: 12px;

        &Title {
          font-family: $ff-lato;
          font-size: map-get($sizes, regular);
          font-style: $fs-normal;
          line-height: 18px;
        }

        &Option {
          width: 136px;
          height: 46px;
          margin: 3px 0;
          font-family: $ff-lato;
          outline: none;
          border: 1px solid map-get($colors, darker-gray);
          background-color: map-get($colors, white);
          cursor: pointer;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:nth-child(2) {
            position: relative;
            border-left: none;

            &.availabilityOptionActive &::before,
            &.availabilityOptionActive &::after {
              position: absolute;
              top: 0;
              left: 100%;
              width: 1px;
              height: 100%;
              content: "";
              background-color: map-get($colors, light-blue);
            }

            &.availabilityOptionActive &::after {
              right: 100%;
              left: inherit;
            }
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &.availabilityOptionActive {
            border: 1px solid map-get($colors, light-blue);
            background: linear-gradient(0deg, rgba(11, 202, 249, 0.05), rgba(11, 202, 249, 0.05)), #fff;
            box-shadow: 0 4px 10px rgba(27, 43, 41, 0.08);
          }
        }
      }
    }
  }
}

.tooltip {
  @include main-font(map-get($sizes, smaller));

  width: 250px;
  padding: 10px !important;
  border-radius: $small-border-radius !important;
  white-space: normal;
  opacity: 1 !important;
}

.availability {
  margin-bottom: 12px;

  .container {
    display: flex;

    :global {
      .conciergeButton {
        @include main-font(map-get($sizes, regular));

        width: 140px;
      }
    }

    .left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .title {
    @include main-font(map-get($sizes, regular));

    line-height: 18px;
  }
}

.switch {
  margin-left: 15px;
}
