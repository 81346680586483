@import '~styles/index.scss';

.switchContainer {
  display: flex;
  align-items: center;

  .switch {
    margin-left: 15px;
  }

  .switchLabel {
    @include main-font(map-get($sizes, smaller));
  }
}
