@import '~styles/index.scss';

.editTemplateModal {
  :global(.conciergeModalBody) {
    span {
      margin-top: 4px;
      font-size: map-get($sizes, smaller);
      color: map-get($colors, pink);
    }
  }
}

.input {
  @include sizing(100% 48px);

  margin-top: 4px;
  padding: 14px 16px;
  font-family: $ff-lato;
  font-size: map-get($sizes, regular);
  line-height: 20px;
  border: 1px solid map-get($colors, darker-gray);
  border-radius: 6px;
  color: map-get($colors, main-black);
  background-color: map-get($colors, white);
}

.switchContainer {
  margin-top: 20px;
}
