@import "~styles/index.scss";
@import 'react-dual-listbox/lib/react-dual-listbox.css';

.dealershipSettings {
  &Wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &Block {
    .conciergeBlockContent {
      display: flex;
      background-color: map-get($colors, light-gray);
      flex-direction: column;
      gap: 10px;
    }

    &Input {
      &Error {
        @include main-font(map-get($sizes, smaller));

        margin-top: 5px;
        color: map-get($colors, pink);
      }
    }
  }

  &SwitchWrapper {
    display: flex;
    padding-top: 20px;
    gap: 20px;
  }

  &EditButton {
    margin-left: auto;
  }

  &Label {
    @include main-font;

    display: block;
    padding: 8px 0;
    font-size: map-get($sizes, smaller);
    line-height: 18px;
    color: map-get($colors, main-black);
  }

  &Select {
    @include main-font;

    color: map-get($colors, main-black);

    .StyledSelect__menu {
      height: 200px;
    }
  }
}

.settingsAccordion {
  margin-bottom: 20px;
}

.dealershipSettingsSwitch {
  width: 100%;
  justify-content: space-between;
}
